import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import EnhancedSwitch from 'components/EnhancedSwitch';
import { datesData, leaveTimeRangeCount, getWeekDay } from 'modules/uitls';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import useViewport from 'hooks/windowSize';
import { tabletMedia } from 'constants';
import ScheduleDate from './ScheduleDate';
import PunchClock from './PunchClock';

dayjs.locale('en')

const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
const useStyles = createUseStyles({
  scheduleTableContent: {
    width: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '200px',
    top: '60px',
    right: 0,
    backgroundColor: 'white',
  },
  scheduleTableColumn: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 64px - 60px)',
    width: '100%',
    [tabletMedia]: {
      display: 'none',
    }
  },
  scheduleTableHeader: {
    height: '60px',
    backgroundColor: '#f6f6f7',
    position: 'absolute',
    left: '0px',
    top: '-60px',
    overflow: 'hidden',
    width: '100%',
    color: '#5f6578',
    fontSize: '16px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    paddingRight: '20px',
    [tabletMedia]: {
      display: 'none'
    }
  },
  dateTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    flexShrink: 0,
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [tabletMedia]: {
      minHeight: '100px',
      padding: 0,
      backgroundColor: '#f6f6f7',
      width: '117px',
      borderLeft: '1px solid #828a99',
      borderRight: '1px solid #828a99',
      borderBottom: '1px solid #d9dce3',
    }
  },
  dateCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fieldManagerTable: {
    display: 'flex',
  },
  fieldManager: {
    position: 'relative',
    width: '116px',
    height: '40px',
    padding: '3px',
    flexShrink: 0,
    overflowY: 'scroll',
    borderTop: '1px solid #d9dce3',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #828a99',
    [tabletMedia]: {
      display: 'none'
    }
  },
  fieldManagerSelect: {
    fontSize: '13px',
    height: '30px',
    fontWeight: '500',
    lineHeight: '2.29',
    textAlign: 'center',
    color: '#8c8c8d'
  },
  scheduleRow: {
    height: '100px',
    width: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [tabletMedia]: {
      display: 'none'
    }
  },
  scheduleRowTable: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
  },
  scheduleRowTableOffDay: {
    position: 'relative',
    width: '116px',
    padding: '3px',
    borderBottom: '1px solid #efeff4',
    flexShrink: 0,
    overflowY: 'scroll',
    backgroundColor: '#f6f7f8'
  },
  scheduleRowCloumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addComment: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px'
  },
  commentButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  addWeeklyLeave: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '20px 20px',
    fontSize: '15px',
  },
  leaveText: {
    display: 'flex',
    flexDirection: 'row',
  },
  leaveButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
  punchClock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    padding: '20px',
  },
  punchClockText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  punchClockButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
  mobileDate: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px - 68px - 60px - 100px - 8px)',
    borderBottom: '1px solid #828a99',
    borderTop: '1px solid #828a99',
    marginTop: '5px',
    overflow: 'scroll',
  },
  mobileTable: {
    display: 'flex',
    flexDirection: 'row',
    [tabletMedia]: {
      width: '100%',
    }
  },
  mobileRow: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff6',
    padding: '6px 3px'
  },
  mobileRowOffDay: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #efeff4',
    backgroundColor: '#e6e6e6',
    padding: '6px 0'
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'blue'
  },
  buttonDelProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -22,
    color: 'blue'
  },
});

const dayMapping = {
  'Mo': '一',
  'Tu': '二',
  'We': '三',
  'Th': '四',
  'Fr': '五',
  'Sa': '六',
  'Su': '日'
}

function ScheduleRows({
  currentUser,
  staffType,
  month,
  year,
  staffData,
  staffKey,
  staffWorktime,
  userRight,
  viewingStaff,
  isTablet,
  weeklyEarnedCount,
  punchClock,
  punchClockRevise,
  punchClockExceptions,
  weeklyLeaves,
  leaves,
  comments,
  overtimes,
  shifts,
  onScrollRow
}) {
  const { formatMessage } = useIntl()
  const { drawerStatus, currentCompany, uiState } = useContext(ContextStore)
  const classes = useStyles();
  const { width, height } = useViewport()
  const [menuInfo, setMenuInfo] = useState(null)
  const [secondMenu, setSecondMenu] = useState(null)
  const [comment, setComment] = useState(null)
  const [commentId, setCommentId] = useState(null)
  const [weeklyLeaveId, setWeeklyLeaveId] = useState(null)
  const [shiftId, setShiftId] = useState(null)
  const [punchClockData, setPunchClockData] = useState([])
  const [punchClockReviseOpen, setPunchClockReviseOpen] = useState(false)
  const [punchClockReviseData, setPunchClockReviseData] = useState({ hour: '09', minute: '00' })
  const [leaveData, setLeaveData] = useState({})
  const [shiftData, setShiftData] = useState({})
  const [timeError, setTimeError] = useState(false)
  const [weeklyError, setWeeklyError] = useState(false)
  const menuOpen = Boolean(menuInfo);
  const headerDatesRef = useRef()
  const dates = datesData(year, month)
  const dataMapping = {
    comment: comments,
    overtime: overtimes,
    leave: leaves,
    weeklyLeave: weeklyLeaves,
    shift: shifts,
    punchClockException: punchClockExceptions
  }
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);
  const [loadingDelComment, setLoadingDelComment] = useState(false);
  const [loadingSaveWeekly, setLoadingSaveWeekly] = useState(false);
  const [loadingDelWeekly, setLoadingDelWeekly] = useState(false);
  const [loadingSaveShift, setLoadingSaveShift] = useState(false);
  const [loadingDelShift, setLoadingDelShift] = useState(false);
  let currentWorktime = {}
  if (menuInfo) {
    currentWorktime = staffWorktime[menuInfo.staff.id]
  }

  let timeMap = {}

  function updateData(field, value, type) {
    if (type === 'leave') {
      let newValue = value
      let newData = { ...leaveData, [field.name]: newValue }

      if (secondMenu.weekly) {
        timeMap = { newData }
      }
      setLeaveData(newData)
    } else if (type === 'shift') {
      let newValue = value
      let newData = { ...shiftData, [field.name]: newValue }

      setShiftData(newData)
    } else {
      let newValue = value
      let newData = { ...punchClockReviseData, [field.name]: newValue }

      setPunchClockReviseData(newData)
    }
  }

  function setCommentData(field, value) {
    let newValue = value
    let newData = { ...comment, [field.name]: newValue }

    setComment(newData)
  }

  const onEdit = (event, content, data, revise) => {
    if (content === 'editComment') {
      setComment(event)
      setCommentId(event.id)
    } else if (content === 'editWeeklyLeave') {
      const starTime = String(event.startTime).split(':')
      const endTime = String(event.endTime).split(':')
      const newData = {
        startHour: starTime[0],
        startMin: starTime[1],
        endHour: endTime[0],
        endMin: endTime[1]
      }
      setLeaveData(newData)
      setWeeklyLeaveId(event.id)
    } else if (content === 'editShift') {
      const starTime = String(event.startTime).split(':')
      const endTime = String(event.endTime).split(':')
      const newData = {
        nightShift: event.nightShift,
        startHour: starTime[0],
        startMin: starTime[1],
        endHour: endTime[0],
        endMin: endTime[1]
      }

      setShiftData(newData)
      setShiftId(event.id)
    } else {
      let newEvent = new PunchClock(dayjs(data.date), event, revise ? revise : [])
      setPunchClockData(newEvent.list)
    }

    setSecondMenu({ [content]: true })
  }

  async function deleteComment() {
    setLoadingDelComment(true)

    try {
      await firebaseV8().collection('scheduleComments').doc(commentId).delete();
      setLoadingDelComment(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteShift() {
    setLoadingDelShift(true)

    try {
      await firebaseV8().collection('shifts').doc(shiftId).delete();
      setLoadingDelShift(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function deleteWeeklyLeave() {
    setLoadingDelWeekly(true)

    try {
      await firebaseV8().collection('weeklyLeaves').doc(weeklyLeaveId).delete();
      setLoadingDelWeekly(false)
      handleClose();
    } catch (ex) {
      console.log(ex)
    }
  }

  async function saveComment() {
    setLoadingSaveComment(true)
    if (secondMenu.editComment) {
      const updateData = {
        ...comment,
        updatedAt: new Date(),
        updatedBy: currentUser.key,
      }
      try {
        await firebaseV8().collection('scheduleComments').doc(commentId).update(updateData)
        setLoadingSaveComment(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveComment(false)
        console.log(ex)
      }
    } else if (secondMenu.comment) {
      const newData = {
        comment: comment.comment || '',
        createdAt: new Date(),
        createdBy: currentUser.key,
        date: dayjs(menuInfo.date).format('YYYY-MM-DD'),
        source: currentCompany,
        staff: menuInfo.staff.id
      }

      try {
        await firebaseV8().collection('scheduleComments').doc().set(newData)
        setLoadingSaveComment(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveComment(false)
        console.log(ex)
      }
    } else {
      setLoadingSaveComment(false)
      handleClose();
    }
  }

  async function saveShift() {
    setLoadingSaveShift(true)
    if(!shiftData.nightShift) {
      if (dayjs(`${dayjs(menuInfo.date).format('YYYY-MM-DD')} ${shiftData.startHour}:${shiftData.startMin}`, 'YYYY-MM-DD HH:mm')
        .isAfter(dayjs(`${dayjs(menuInfo.date).format('YYYY-MM-DD')} ${shiftData.endHour}:${shiftData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
        setTimeError(true)
        setLoadingSaveShift(false)
        return false
      } 
    }

    if (secondMenu.editShift) {
      const newData = {
        startTime: `${shiftData.startHour}:${shiftData.startMin}`,
        endTime: `${shiftData.endHour}:${shiftData.endMin}`,
        updatedAt: new Date(),
        updatedBy: currentUser.key,
      }

      try {
        await firebaseV8().collection('shifts').doc(shiftId).update(newData)
        setLoadingSaveShift(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveShift(false)
        console.log(ex)
      }
    } else {
      let newData = {}
      if(shiftData.nightShift) {
        const nextData = {
          startTime: '00:00',
          endTime: `${shiftData.endHour}:${shiftData.endMin}`,
          date: dayjs(menuInfo.date).add(1, 'day').format('YYYY-MM-DD'),
          source: currentCompany,
          nightShift: shiftData.nightShift,
          createdAt: new Date(),
          createdBy: currentUser.key,
          staff: menuInfo.staff.id
        }

        try {
          await firebaseV8().collection('shifts').doc().set(nextData)
          setLoadingSaveShift(false)
        } catch (ex) {
          console.log(ex)
        }

        newData = {
          startTime: `${shiftData.startHour}:${shiftData.startMin}`,
          endTime: '24:00',
          date: dayjs(menuInfo.date).format('YYYY-MM-DD'),
          source: currentCompany,
          nightShift: shiftData.nightShift,
          createdAt: new Date(),
          createdBy: currentUser.key,
          staff: menuInfo.staff.id
        }
      } else {
        newData = {
          startTime: `${shiftData.startHour}:${shiftData.startMin}`,
          endTime: `${shiftData.endHour}:${shiftData.endMin}`,
          date: dayjs(menuInfo.date).format('YYYY-MM-DD'),
          source: currentCompany,
          nightShift: shiftData.nightShift,
          createdAt: new Date(),
          createdBy: currentUser.key,
          staff: menuInfo.staff.id
        }
      }
      
      try {
        await firebaseV8().collection('shifts').doc().set(newData)
        setLoadingSaveShift(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveShift(false)
        console.log(ex)
      }
    }
  }

  async function saveWeeklyLeave() {
    setLoadingSaveWeekly(true)
    const workTime = currentWorktime[getWeekDay(dayjs(menuInfo.date))] || '00:00'
    if (`${leaveData.startHour}:${leaveData.startMin}` < workTime.startTime || `${leaveData.endHour}:${leaveData.endMin}` > workTime.endTime) {
      setTimeError(true)
      setLoadingSaveWeekly(false)
      return false
    }

    if (dayjs(`${dayjs(menuInfo.date).format('YYYY-MM-DD')} ${leaveData.startHour}:${leaveData.startMin}`, 'YYYY-MM-DD HH:mm')
      .isAfter(dayjs(`${dayjs(menuInfo.date).format('YYYY-MM-DD')} ${leaveData.endHour}:${leaveData.endMin}`, 'YYYY-MM-DD HH:mm'))) {
      setTimeError(true)
      setLoadingSaveWeekly(false)
      return false
    }

    const pce = punchClockExceptions.reduce((acc, cur) => {
      if (cur.date === dayjs(menuInfo.date).format('YYYY-MM-DD')) {
        acc[cur.date] = { ...cur }
      }

      return acc
    }, {})

    const staffWeekly = weeklyLeaves.filter(s => s.staff === menuInfo.staff.id)
    const weeklyTotal = weeklyEarnedCount(menuInfo.staff.id, true)
    const newLeaveData = {
      startDate: menuInfo.date,
      endDate: menuInfo.date,
      startHour: leaveData.startHour,
      startMinute: leaveData.startMin,
      endHour: leaveData.endHour,
      endMinute: leaveData.endMin,
      leaveType: 'weekly_leave'
    }

    const currentTime = leaveTimeRangeCount(newLeaveData, staffData[menuInfo.staff.id].worktimeType, currentWorktime, pce, staffData[menuInfo.staff.id])
    const currentHours = currentTime ? (currentTime.day * 8) + currentTime.hour : 0

    let hours = 0
    for (const data of staffWeekly) {
      let newData = {
        startDate: data.date,
        endDate: data.date,
        startHour: data.startTime.split(':')[0],
        startMinute: data.startTime.split(':')[1],
        endHour: data.endTime.split(':')[0],
        endMinute: data.endTime.split(':')[1],
      }
      if (secondMenu.editWeeklyLeave) {
        if (weeklyLeaveId === data.id) {
          newData = {
            ...newData,
            startHour: leaveData.startHour,
            startMinute: leaveData.startMin,
            endHour: leaveData.endHour,
            endMinute: leaveData.endMin,
          }
        }
      }

      const leaveOfTime = leaveTimeRangeCount(newData, staffData[menuInfo.staff.id].worktimeType, currentWorktime, pce, staffData[menuInfo.staff.id])

      if (leaveOfTime) {
        hours += (leaveOfTime.day * 8) + leaveOfTime.hour
      }
    }

    if (secondMenu.editWeeklyLeave) {
      if (hours > weeklyTotal) {
        setWeeklyError(true)
        setLoadingSaveWeekly(false)
        return false
      }
      const newData = {
        startTime: `${leaveData.startHour}:${leaveData.startMin}`,
        endTime: `${leaveData.endHour}:${leaveData.endMin}`,
        updatedAt: new Date(),
        updatedBy: currentUser.key,
      }

      try {
        await firebaseV8().collection('weeklyLeaves').doc(weeklyLeaveId).update(newData)
        setLoadingSaveWeekly(false)
        setWeeklyError(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveWeekly(false)
        setWeeklyError(false)
        console.log(ex)
      }
    } else {
      if ((currentHours + hours) > weeklyTotal) {
        setWeeklyError(true)
        setLoadingSaveWeekly(false)
        return false
      }

      const newData = {
        startTime: `${timeMap.startHour || leaveData.startHour}:${timeMap.startMin || leaveData.startMin}`,
        endTime: `${timeMap.endHour || leaveData.endHour}:${timeMap.endMin || leaveData.endMin}`,
        date: dayjs(menuInfo.date).format('YYYY-MM-DD'),
        createdAt: new Date(),
        createdBy: currentUser.key,
        staff: menuInfo.staff.id
      }

      try {
        await firebaseV8().collection('weeklyLeaves').doc().set(newData)
        setLoadingSaveWeekly(false)
        setWeeklyError(false)
        handleClose();
      } catch (ex) {
        setLoadingSaveWeekly(false)
        setWeeklyError(false)
        console.log(ex)
      }
    }
  }

  function openAddMenu() {
    if (secondMenu.comment || secondMenu.editComment) {
      return (
        <div className={classes.addComment}>
          {secondMenu.comment ? formatMessage({ id: 'schedule.anchor.add_comment' }) : formatMessage({ id: 'schedule.anchor.edit_comment' })}
          {secondMenu.editComment ? <TextField
            margin="normal"
            multiline
            variant="outlined"
            fullWidth
            size="medium"
            rows="2"
            value={comment.comment}
            onChange={(e) => setCommentData({ name: 'comment' }, e.target.value)}
          /> : <TextField
            margin="normal"
            multiline
            variant="outlined"
            fullWidth
            size="medium"
            rows="2"
            onChange={(e) => setCommentData({ name: 'comment' }, e.target.value)}
          />}
          <div className={classes.commentButton}>
            {secondMenu.editComment && <div className={classes.wrapper}>
              <Button style={{ marginRight: '20px' }} variant="contained" onClick={() => deleteComment()} color="secondary" disabled={loadingSaveComment || loadingDelComment}>
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {loadingDelComment && <CircularProgress size={24} className={classes.buttonDelProgress} />}
            </div>}
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            <div className={classes.wrapper}>
              <Button variant="contained" onClick={() => saveComment()} color="primary" disabled={loadingSaveComment || loadingDelComment}>
                {secondMenu.editComment ? formatMessage({ id: 'button.save' }) : formatMessage({ id: 'button.add' })}
              </Button>
              {loadingSaveComment && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      );
    } else if (secondMenu.weekly || secondMenu.editWeeklyLeave) {
      return (
        <div className={classes.addWeeklyLeave}>
          {secondMenu.weekly ? formatMessage({ id: 'schedule.anchor.add_weekly_leave' }) : formatMessage({ id: 'schedule.anchor.edit_weekly_leave' })}
          <div>{formatMessage({ id: 'schedule.anchor.start' })}</div>
          <div className={classes.leaveText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'startHour' }, e.target.value, 'leave')}
              value={leaveData.startHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'startMin' }, e.target.value, 'leave')}
              value={leaveData.startMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>

          <div>{formatMessage({ id: 'schedule.anchor.end' })}</div>
          <div className={classes.leaveText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'endHour' }, e.target.value, 'leave')}
              value={leaveData.endHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'endMin' }, e.target.value, 'leave')}
              value={leaveData.endMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>
          {timeError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.time.formatError' })}</div>}
          {weeklyError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.time.weeklyLeave' })}</div>}
          <div className={classes.leaveButton}>
            {secondMenu.editWeeklyLeave && <div className={classes.wrapper}>
              <Button style={{ marginRight: '20px' }} variant="contained" onClick={() => deleteWeeklyLeave()} color="secondary" disabled={loadingSaveWeekly || loadingDelWeekly}>
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {loadingDelWeekly && <CircularProgress size={24} className={classes.buttonDelProgress} />}
            </div>}
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            <div className={classes.wrapper}>
              <Button variant="contained" onClick={() => saveWeeklyLeave()} color="primary" disabled={loadingSaveWeekly || loadingDelWeekly}>
                {secondMenu.editWeeklyLeave ? formatMessage({ id: 'button.save' }) : formatMessage({ id: 'button.add' })}
              </Button>
              {loadingSaveWeekly && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      );
    } else if (secondMenu.shift || secondMenu.editShift) {
      return (
        <div className={classes.addWeeklyLeave}>
          {secondMenu.shift ? formatMessage({ id: 'schedule.anchor.add_shift' }) : formatMessage({ id: 'schedule.anchor.edit_shift' })}
          <div>{formatMessage({ id: 'schedule.anchor.start' })}</div>
          <div className={classes.leaveText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'startHour' }, e.target.value, 'shift')}
              value={shiftData.startHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'startMin' }, e.target.value, 'shift')}
              value={shiftData.startMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>
          <div>{formatMessage({ id: 'schedule.anchor.end' })}</div>
          <div className={classes.leaveText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'endHour' }, e.target.value, 'shift')}
              value={shiftData.endHour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'endMin' }, e.target.value, 'shift')}
              value={shiftData.endMin}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>
          {timeError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.time.formatError' })}</div>}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <EnhancedSwitch 
              checked={shiftData.nightShift} 
              onChange={e => updateData({ name: 'nightShift' }, e.target.checked, 'shift')} 
              rightLabel="夜班" 
              leftLabel='正常班'
            />
          </div>
          <div className={classes.leaveButton}>
            {secondMenu.editShift && <div className={classes.wrapper}>
              <Button style={{ marginRight: '20px' }} variant="contained" onClick={() => deleteShift()} color="secondary" disabled={loadingSaveShift || loadingDelShift}>
                {formatMessage({ id: 'button.delete' })}
              </Button>
              {loadingDelShift && <CircularProgress size={24} className={classes.buttonDelProgress} />}
            </div>}
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
            <div className={classes.wrapper}>
              <Button variant="contained" onClick={() => saveShift()} color="primary" disabled={loadingSaveShift || loadingDelShift}>
                {secondMenu.editShift ? formatMessage({ id: 'button.save' }) : formatMessage({ id: 'button.add' })}
              </Button>
              {loadingSaveShift && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.punchClock}>
          {formatMessage({ id: 'schedule.anchor.punchClock' })}
          <Grid container spacing={1}>
            {punchClockData.map(pc => {
              return <Grid item key={pc.time} xs={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ color: pc.revised ? 'blue' : '' }}>{pc.time}</div>
              </Grid>
            })}
          </Grid>
          {punchClockReviseOpen && <div className={classes.punchClockText}>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              style={{ marginRight: '10px' }}
              onChange={(e) => updateData({ name: 'hour' }, e.target.value, 'punchCLock')}
              value={punchClockReviseData.hour}
            >
              {hours.map(hour => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={hour} value={hour}>{hour}</MenuItem>
              })}
            </TextField>
            <TextField
              margin="normal"
              multiline
              variant="outlined"
              size="small"
              select
              onChange={(e) => updateData({ name: 'minute' }, e.target.value, 'punchCLock')}
              value={punchClockReviseData.minute}
            >
              {minutes.map(minute => {
                return <MenuItem style={{ padding: '0px 0 0 5px' }} key={minute} value={minute}>{minute}</MenuItem>
              })}
            </TextField>
          </div>}
          <div className={classes.punchClockButton}>
            <Button style={{ marginRight: '10px' }} variant="contained" onClick={handleClose} color="inherit">
              {formatMessage({ id: 'button.close' })}
            </Button>
          </div>
        </div>
      );
    }
  }

  function onContentScroll({ target: { scrollLeft, scrollTop } }) {
    requestAnimationFrame(() => {
      headerDatesRef.current.scrollLeft = scrollLeft
      onScrollRow(scrollTop)
    });
  }

  function openSecondMenu(event) {
    setSecondMenu({ [event]: true })

    if (event === 'weekly') {
      const workTime = currentWorktime[getWeekDay(dayjs(menuInfo.date))] || { startTime: '09:00', endTime: '18:00' }
      const startT = workTime.startTime.split(':')
      const endT = workTime.endTime.split(':')
      timeMap.startHour = startT[0]
      timeMap.startMin = startT[1]
      timeMap.endHour = endT[0]
      timeMap.endMin = endT[1]

      setLeaveData(timeMap)
    } else if (event === 'shift') {
      setShiftData({
        nightShift: false,
        startHour: '09',
        startMin: '00',
        endHour: '18',
        endMin: '00',
      })
    }
  }

  const handleMenu = (event, date, staff) => {
    const exception = punchClockExceptions.filter(p => p.date === dayjs(date).format('YYYY-MM-DD') && p.type === 'on')
    if (dayjs(date).day() !== 0|| exception.length > 0 || staff.worktimeType === 'shift') {
      if (staff.worktimeType !== 'fixedShift' || secondMenu) {
        if (!userRight.hasUserRightForVendor('schedule-edit', currentCompany) && secondMenu) {
          setMenuInfo({ date, staff, anchor: event.currentTarget })
        } else if (userRight.hasUserRightForVendor('schedule-edit', currentCompany)) {
          setMenuInfo({ date, staff, anchor: event.currentTarget })
        }
      }
    } else {
      setMenuInfo(null)
    }
  };

  const handleClose = () => {
    setMenuInfo(null);
    setSecondMenu(null);
    setCommentId(null);
    setWeeklyLeaveId(null);
    setComment(null);
    setShiftId(null)
    setPunchClockReviseOpen(false);
    setTimeError(false)
    setWeeklyError(false)
    setPunchClockReviseData({
      hour: '09',
      minute: '00'
    })
    setShiftData({
      startHour: '00',
      startMin: '00',
      endHour: '00',
      endMin: '00'
    })
    setLeaveData({
      startHour: '00',
      startMin: '00',
      endHour: '00',
      endMin: '00'
    })
  };

  if (isTablet && viewingStaff) {
    return (
      <div className={classes.mobileDate} style={{ maxHeight: `calc(${height}px - 290px` }}>
        {menuInfo && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isTablet ? 'center' : 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: isTablet ? 'center' : 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('comment')}>
              {formatMessage({ id: 'schedule.anchor.add_comment' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('weekly')}>
              {formatMessage({ id: 'schedule.anchor.add_weekly_leave' })}
            </MenuItem>
            {staffData[viewingStaff].worktimeType === 'shift' && <MenuItem onClick={() => openSecondMenu('shift')}>
              {formatMessage({ id: 'schedule.anchor.add_shift' })}
            </MenuItem>}
          </span> : openAddMenu()}
        </Menu>}
        {dates.map(date => {
          let isDayOff = false;
          const weekday = dayjs(date).day()
          // if (staffData[viewingStaff].worktimeType !== 'fixedShift') {
          //   isDayOff = weekday === 0;
          // } else {
          //   isDayOff = (weekday === 6 || weekday === 0);
          // }

          const ex = punchClockExceptions.reduce((acc, cur) => {
            if (cur.date === dayjs(date).format('YYYY-MM-DD') && cur.department.includes(staffType)) {
              acc = cur.type
            }
            return acc
          }, '')
          
          if (staffData[viewingStaff].worktimeType !== 'shift') {
            const workDay = []
            Object.keys(staffWorktime[viewingStaff]).forEach(week => {
              if (week === 'Sun') {
                workDay.push(0)
              } else if (week === 'Mon') {
                workDay.push(1)
              } else if (week === 'Tue') {
                workDay.push(2)
              } else if (week === 'Wed') {
                workDay.push(3)
              } else if (week === 'Thu') {
                workDay.push(4)
              } else if (week === 'Fri') {
                workDay.push(5)
              } else if (week === 'Sat') {
                workDay.push(6)
              }
            })
            isDayOff = !workDay.includes(weekday)
          }

          if (ex) {
            isDayOff = ex === 'off' ? true : false
          }

          return <div className={classes.mobileTable} style={{ maxWidth: width }} key={date}>
            <div className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
            <div className={!isDayOff ? classes.mobileRow : classes.mobileRowOffDay} onClick={(e) => handleMenu(e, date, staffData[viewingStaff])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDate
                    date={date}
                    staff={staffData[viewingStaff]}
                    staffId={viewingStaff}
                    dataMapping={dataMapping}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    workTime={staffWorktime[viewingStaff]}
                    isDayOff={isDayOff}
                    userRight={userRight}
                    currentCompany={currentCompany}
                    uiState={uiState}
                  />
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    );
  }

  const dateRows = staffKey.map((key, idx) => {
    return <div key={idx}>
      <div className={classes.scheduleRow}>
        {dates.map(date => {
          let isDayOff = false;
          const weekday = dayjs(date).day()
          const ex = punchClockExceptions.reduce((acc, cur) => {
            if (cur.date === dayjs(date).format('YYYY-MM-DD') && cur.department.includes(staffType)) {
              acc = cur.type
            }
            return acc
          }, '')

          if (staffData[key].worktimeType !== 'shift') {
            const workDay = []
            Object.keys(staffWorktime[key]).forEach(week => {
              if (week === 'Sun') {
                workDay.push(0)
              } else if (week === 'Mon') {
                workDay.push(1)
              } else if (week === 'Tue') {
                workDay.push(2)
              } else if (week === 'Wed') {
                workDay.push(3)
              } else if (week === 'Thu') {
                workDay.push(4)
              } else if (week === 'Fri') {
                workDay.push(5)
              } else if (week === 'Sat') {
                workDay.push(6)
              }
            })
            isDayOff = !workDay.includes(weekday)
          }

          if (ex) {
            isDayOff = ex === 'off' ? true : false
          }

          return (
            <div key={date} className={!isDayOff ? classes.scheduleRowTable : classes.scheduleRowTableOffDay} onClick={(e) => handleMenu(e, date, staffData[key])}>
              <div className={classes.scheduleRowCloumn}>
                <div>
                  <ScheduleDate
                    date={date}
                    staff={staffData[key]}
                    staffId={key}
                    dataMapping={dataMapping}
                    onSubmit={onEdit}
                    punchClock={punchClock}
                    punchClockRevise={punchClockRevise}
                    workTime={staffWorktime[key]}
                    isDayOff={isDayOff}
                    userRight={userRight}
                    currentCompany={currentCompany}
                    uiState={uiState}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  })

  return (
    <div className={classes.scheduleTableContent}>
      <div className={classes.scheduleTableColumn} onScroll={onContentScroll}>
        {menuInfo && <Menu
          id="menu-appbar"
          anchorEl={menuInfo.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          {!secondMenu ? <span>
            <MenuItem onClick={() => openSecondMenu('comment')}>
              {formatMessage({ id: 'schedule.anchor.add_comment' })}
            </MenuItem>
            <MenuItem onClick={() => openSecondMenu('weekly')}>
              {formatMessage({ id: 'schedule.anchor.add_weekly_leave' })}
            </MenuItem>
            {staffData[menuInfo.staff.id].worktimeType === 'shift' && <MenuItem onClick={() => openSecondMenu('shift')}>
              {formatMessage({ id: 'schedule.anchor.add_shift' })}
            </MenuItem>}
          </span> : openAddMenu()}
        </Menu>}
        <div className={clsx(classes.scheduleTableHeader, { [classes.drawer]: drawerStatus })} style={{ borderBottom: '1px solid #828a99' }} ref={headerDatesRef}>
          {dates.map(date => {
            return <div key={date} className={classes.dateTable}>
              <div className={classes.dateCloumn}>
                <div>{`${date.format('M/D')} (${dayMapping[date.format('dd')]})`}</div>
              </div>
            </div>
          })}
        </div>
        {dateRows}
      </div>
    </div>
  );
}


ScheduleRows.propTypes = {
  currentUser: PropTypes.shape({
    key: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  staffType: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  staffData: PropTypes.object,
  staffKey: PropTypes.arrayOf(PropTypes.string.isRequired),
  staffWorktime: PropTypes.object.isRequired,
  userRight: PropTypes.object.isRequired,
  viewingStaff: PropTypes.string,
  isTablet: PropTypes.bool,
  weeklyEarnedCount: PropTypes.func.isRequired,
  punchClock: PropTypes.object.isRequired,
  punchClockRevise: PropTypes.object.isRequired,
  punchClockExceptions: PropTypes.array.isRequired,
  weeklyLeaves: PropTypes.arrayOf(PropTypes.object.isRequired),
  leaves: PropTypes.arrayOf(PropTypes.object.isRequired),
  comments: PropTypes.arrayOf(PropTypes.object.isRequired),
  overtimes: PropTypes.arrayOf(PropTypes.object.isRequired),
  shifts: PropTypes.arrayOf(PropTypes.object.isRequired),
  onScrollRow: PropTypes.func.isRequired
};

export default ScheduleRows;

