import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { createUseStyles } from 'react-jss';

import CelebrationIcon from '@mui/icons-material/Celebration';

import { sortTimeArray, mergeTimeArray, findNextPunchIn, findNextPunchOut, splitShiftByLeaves, getWeekDay } from 'modules/uitls';
import { tabletMedia } from 'constants';
import PunchClock from './PunchClock';

const useStyles = createUseStyles({
  dateComment: {
    marginBottom: '3px',
    color: '#0000ff',
    backgroundColor: '#efeff4',
    textAlign: 'center',
    // whiteSpace: 'nowrap',
    lineHeight: 1.2,
    padding: '1px',
    fontSize: '14px',
    borderRadius: '2px',
    width: '85px',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    overflowWrap: 'break-word',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px'
    }
  },
  dateOvertime: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: 'brown',
    textAlign: 'center',
    // whiteSpace: 'nowrap',
    lineHeight: 1.2,
    padding: '1px',
    fontSize: '14px',
    borderRadius: '2px',
    width: '85px',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    overflowWrap: 'break-word',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px'
    }
  },
  dateLeave: {
    marginBottom: '3px',
    color: '#efeff4',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '1px',
    display: 'block',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '85px',
    borderRadius: '.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px',
      flexDirection: 'row'
    }
  },
  dateShifts: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: '#868e96',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: 1.2,
    padding: '1px',
    fontSize: '12px',
    borderRadius: '.25rem',
    width: '85px',
    verticalAlign: 'baseline',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px'
    }
  },
  datePunchClock: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: '#ffbf38',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: 1.2,
    padding: '1px',
    fontSize: '12px',
    borderRadius: '.25rem',
    width: '85px',
    verticalAlign: 'baseline',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px'
    }
  },
  datePunchClockException: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: '#17a2b8',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '1px',
    display: 'block',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '85px',
    borderRadius: '.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [tabletMedia]: {
      width: '160px',
      letterSpacing: '5px',
      flexDirection: 'row'
    }
  },
  punchClockError: {
    color: 'red'
  },
  punchClockRevised: {
    color: 'blue'
  },
  birthDate: {
    marginBottom: '3px',
    color: '#fff',
    backgroundColor: 'yellowgreen',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '1px',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: '85px',
    borderRadius: '.25rem',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

function ScheduleDate({ date, staff, staffId, dataMapping, onSubmit, punchClock, punchClockRevise, workTime, isDayOff, userRight, currentCompany, uiState }) {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const { formatMessage } = useIntl()
  const classes = useStyles();
  const punchClockExceptions = dataMapping.punchClockException ? dataMapping.punchClockException.filter(m => dayjs(m.date).isSame(dayjs(date)) && m.department.includes(staff.department)) : []
  const comments = dataMapping.comment && dataMapping.comment.filter(m => dayjs(m.date).isSame(dayjs(date)) && m.staff === staff.id)
  const overtimes = dataMapping.overtime ? dataMapping.overtime.filter(m => m.createdBy === staff.id && (dayjs(date).isSameOrAfter(dayjs(m.startDate)) && dayjs(date).isSameOrBefore(dayjs(m.endDate)))) : []
  const weeklyLeaves = dataMapping.weeklyLeave ? dataMapping.weeklyLeave.filter(m => dayjs(m.date).isSame(dayjs(date)) && m.staff === staff.id) : []
  const leaves = dataMapping.leave ? dataMapping.leave.filter(m => m.createdBy === staff.id && (dayjs(date).isSameOrAfter(dayjs(m.startDate)) && dayjs(date).isSameOrBefore(dayjs(m.endDate)))) : []
  const currentShift = dataMapping.shift ? dataMapping.shift.filter(m => dayjs(m.date).isSame(dayjs(date)) && m.staff === staff.id) : []
  const punchClocks = punchClock[dayjs(date).format('YYYY-MM-DD')] ? punchClock[dayjs(date).format('YYYY-MM-DD')][staff.id] : []
  const punchClockRevises = punchClockRevise[dayjs(date).format('YYYY-MM-DD')] ? punchClockRevise[dayjs(date).format('YYYY-MM-DD')][staff.id] : []
  const punchClockArray = punchClockAlgorithm();
  const birthDate = staff?.birthDate ?? ''

  function onEditClick(event, content) {
    if (content === 'punchClock') {
      const punchClockData = {
        date: dayjs(date).format('YYYY-MM-DD'),
        staffId: staffId
      }
      onSubmit(punchClocks, content, punchClockData, punchClockRevises);
    } else {
      if (!userRight.hasUserRightForVendor('schedule-edit', currentCompany)) {
        return false
      }

      onSubmit(event, content);
    }
  }

  function punchClockAlgorithm() {
    let newPunchClock = new PunchClock(date, punchClocks, punchClockRevises)
    const weekday = dayjs(date).day();
    const exceptionOff = punchClockExceptions.filter(pc => pc.type === 'off');
    const exceptionOn = punchClockExceptions.filter(pc => pc.type === 'on');
    const sortedLeaves = sortTimeArray(leaves.concat(exceptionOff).concat(weeklyLeaves));

    let weekdayShift = [];

    if (staff.worktimeType === 'shift' && currentShift.length > 0) {
      if(currentShift.length > 1) {
        currentShift.forEach(s => {
          weekdayShift.push({
            date: dayjs(date).format('YYYY-MM-DD'),
            startTime: s.startTime,
            endTime: s.endTime,
            nightShift: s.nightShift
          })
        })
      } else {
        weekdayShift = {
          date: dayjs(date).format('YYYY-MM-DD'),
          startTime: currentShift[0].startTime,
          endTime: currentShift[0].endTime,
          nightShift: currentShift[0].nightShift
        }
      }
      
    } else if (staff.worktimeType !== 'fixedShift') {
      if (weekday === 0) {
        weekdayShift = [];
      } else {
        weekdayShift = {
          date: dayjs(date).format('YYYY-MM-DD'),
          startTime: workTime[getWeekDay(dayjs(date))]?.startTime ?? '',
          endTime: workTime[getWeekDay(dayjs(date))]?.endTime ?? ''
        }
      }
    } else {
      if (weekday === 0 || weekday === 6) {
        weekdayShift = [];
      } else {
        weekdayShift = {
          date: dayjs(date).format('YYYY-MM-DD'),
          startTime: workTime[getWeekDay(dayjs(date))]?.startTime ?? '',
          endTime: workTime[getWeekDay(dayjs(date))]?.endTime ?? ''
        }
      }
    }

    let shift = weekdayShift;
    
    if(shift.length > 0) {
      shift = shift.reduce((acc, cur) => {
        acc.push({
          ...cur,
          startTimeM: dayjs(date).format('YYYY-MM-DD') + ' ' + cur.startTime,
          endTimeM: dayjs(date).format('YYYY-MM-DD') + ' ' + cur.endTime
        })
        return acc
      }, [])
    } else {
      shift.startTimeM = dayjs(date).format('YYYY-MM-DD') + ' ' + shift.startTime
      shift.endTimeM = dayjs(date).format('YYYY-MM-DD') + ' ' + shift.endTime
    }
  
    const fullShifts = mergeTimeArray(sortTimeArray(exceptionOn.map(e => ({
      date: e.date,
      startTime: e.startTime,
      endTime: e.endTime,
      startTimeM: dayjs(`${e.date} ${e.startTime}`, 'YYYY-MM-DD HH:mm'),
      endTimeM: dayjs(`${e.date} ${e.endTime}`, 'YYYY-MM-DD HH:mm'),
    })).concat(shift)));

    let shifts = [];
    for (const shift of fullShifts) {
      shifts = shifts.concat(splitShiftByLeaves(shift, sortedLeaves));
    }

    if (shifts.length === 0 && !newPunchClock.isEmpty) {
      let punchClockDupe = newPunchClock.all.concat();
      const endTime = punchClockDupe[punchClockDupe.length - 1]
      const startTime = punchClockDupe[0]
      shifts.push({
        date: date,
        endTime: endTime,
        startTime: startTime,
        startTimeM: dayjs(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm'),
        endTimeM: dayjs(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm')
      })
    }

    let punchClockArrary = [];

    if (!newPunchClock.isEmpty) {
      if (isDayOff) {
        let punchInTime = newPunchClock.first;
        let punchOutTime = newPunchClock.last;
        punchClockArrary.push({
          key: punchClockArrary.length,
          punchInTime,
          punchOutTime,
          punchInRevised: punchClockRevises && punchClockRevises.includes(punchInTime),
          punchOutRevised: punchClockRevises && punchClockRevises.includes(punchOutTime)
        });
      } else {
        let punchClockDupe = newPunchClock.all.concat();

        for (let i = 0; i < shifts.length; ++i) {
          let last = i === shifts.length - 1;
          let shift = shifts[i];
          if(shift.nightShift)  {
            punchClockDupe = newPunchClock.all.concat();
            if(shift.startTime === '00:00') {
              punchClockDupe.push(shift.startTime)
              punchClockDupe = punchClockDupe.filter(p => p < '12:00')
            }

            if(shift.endTime === '24:00') {
              punchClockDupe.push(shift.endTime)
              punchClockDupe = punchClockDupe.filter(p => p > '12:00')
            }
          }

          punchClockDupe.sort()

          if (shift.ignorePunchClock) continue;

          let shiftStartTime = shift.startTimeS || shift.startTime;
          let shiftEndTime = shift.endTimeS || shift.endTime;

          if (shiftStartTime === '-' && shiftEndTime === '-') {
            punchClockArrary.push({
              key: punchClockArrary.length,
              punchInTime: '-',
              punchOutTime: '-',
              punchInError: false,
              punchOutError: false
            });
          } else {
            const { punchInTime, punchInError } = findNextPunchIn(punchClockDupe, date, shiftStartTime);
            const { punchOutTime, punchOutError, nextPunchClock } = findNextPunchOut(punchClockDupe, date, shiftEndTime, last, false);
            
            punchClockArrary.push({
              key: punchClockArrary.length,
              punchInTime: shift.nightShift && shiftStartTime === '00:00' ? '--' : punchInTime,
              punchOutTime: shift.nightShift && shiftEndTime === '24:00' ? '--' : punchOutTime,
              punchInError,
              punchOutError,
              punchInRevised: punchClockRevises && punchClockRevises.includes(punchInTime),
              punchOutRevised: punchClockRevises && punchClockRevises.includes(punchOutTime)
            });
            if (!last) punchClockDupe = nextPunchClock || [];
          }
        }
      }
    } else {
      if (!isDayOff && shifts.length && !newPunchClock.isExist) {
        punchClockArrary.push({
          key: punchClockArrary.length,
          punchInTime: '-',
          punchOutTime: '-',
          punchInError: true,
          punchOutError: true
        });
      }
    }

    return punchClockArrary
  }

  function reduceLeaves(leave) {
    if (leave.startDate !== leave.endDate) {
      if (dayjs(leave.startDate).isSame(dayjs(date))) {
        return `${leave.startTime}-`
      } else if (dayjs(leave.endDate).isSame(dayjs(date))) {
        return `-${leave.endTime}`
      } else {
        return '---'
      }
    }

    return `${leave.startTime}-${leave.endTime}`
  }

  return (
    <div>
      {birthDate && dayjs(date).format('MMDD') === dayjs(birthDate).format('MMDD') && <div className={classes.birthDate}>
        {'生日快樂'}
        <CelebrationIcon />
      </div>}
      {uiState.showComment && comments && comments.map((comment, idx) => <div className={classes.dateComment} key={idx} onClick={() => onEditClick(comment, 'editComment')}>
        <label>{comment.comment}</label>
      </div>)}
      {uiState.showShift && currentShift.length > 0 && currentShift.map((shift, idx) => <div className={classes.dateShifts} key={idx} onClick={() => onEditClick(shift, 'editShift')}>
        <div>
          {shift.nightShift && shift.startTime === '00:00' ? '--' : shift.startTime}
          -
          {shift.nightShift && shift.endTime === '24:00' ? '--' : shift.endTime}
        </div>
      </div>)}
      {uiState.showPunchClock && punchClockArray && punchClockArray.map((pc, idx) => (pc.punchInTime !== '-' || pc.punchOutTime !== '-') && <div className={classes.datePunchClock} key={idx} onClick={() => onEditClick(pc, 'punchClock')}>
        <label className={pc.punchInError ? classes.punchClockError : (pc.punchInRevised ? classes.punchClockRevised : '')}>
          {pc.punchInTime}
        </label>-
        <label className={pc.punchOutError ? classes.punchClockError : (pc.punchOutRevised ? classes.punchClockRevised : '')}>
          {pc.punchOutTime}
        </label>
      </div>)}
      {uiState.showOvertime && overtimes.length > 0 && overtimes.map((overtime, idx) => {
        if(overtime.startDate === overtime.endDate) {
          return <div className={classes.dateOvertime} key={idx}>
            <div>{overtime.startTime}-{overtime.endTime}</div>
          </div>
        } else {
          if(overtime.startDate === dayjs(date).format('YYYY-MM-DD')) {
            return <div className={classes.dateOvertime} key={idx}>
              <div>{overtime.startTime}---</div>
            </div>
          } else if (overtime.endDate === dayjs(date).format('YYYY-MM-DD')) {
            return <div className={classes.dateOvertime} key={idx}>
              <div>---{overtime.endTime}</div>
            </div>
          }
        }
      })}
      {uiState.showException && punchClockExceptions && punchClockExceptions.map((pcE, idx) => <div className={classes.datePunchClockException} key={idx}>
        <div>
          {pcE.reason}
        </div>
        <div>
          {pcE.startTime}-{pcE.endTime}
        </div>
      </div>)}
      {uiState.showLeave && leaves.length > 0 && !isDayOff && leaves.map((leave, idx) => <div className={classes.dateLeave} style={{ backgroundColor: '#fe2851' }} key={idx}>
        <div>
          {formatMessage({ id: `leaveType.${leave.type}` })}
        </div>
        <div>
          {reduceLeaves(leave)}
        </div>
      </div>)}
      {uiState.showWeeklyLeave && weeklyLeaves && weeklyLeaves.map((leave, idx) =>
        <div className={classes.dateLeave} style={{ backgroundColor: '#3f51b5' }} key={idx} onClick={() => onEditClick(leave, 'editWeeklyLeave')}>
          <div> {'本休'}</div>
          <div>
            {leave.startTime}-{leave.endTime}
          </div>
        </div>)}
      {uiState.showWeeklyLeave && !['fixedShift', 'shift'].includes(staff.worktimeType) &&
        isDayOff && punchClockExceptions.length <= 0 && <div className={classes.dateLeave} style={{ backgroundColor: '#3f51b5' }}>
        <div>{'本休'}</div>
        <div>{'All day'}</div>
      </div>}
    </div >
  );

}


ScheduleDate.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  date: PropTypes.object.isRequired,
  staff: PropTypes.object.isRequired,
  staffId: PropTypes.string.isRequired,
  dataMapping: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  punchClock: PropTypes.object.isRequired,
  punchClockRevise: PropTypes.object.isRequired,
  workTime: PropTypes.object.isRequired,
  isDayOff: PropTypes.bool.isRequired,
  userRight: PropTypes.object.isRequired,
  currentCompany: PropTypes.string.isRequired,
  uiState: PropTypes.object.isRequired
};

export default ScheduleDate;
